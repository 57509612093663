import {
  createSettingsParams,
  ISettingsParam,
  PartialBy,
  SettingsParamType,
} from '@wix/tpa-settings';
import calendarSettingsParams, {
  ISettingsParams,
} from '../BookingCalendar/settingsParams';
import { AlignmentOptions, LayoutOptions } from '../../types/types';
import { AccessibilityHtmlTags } from '../../utils/accessibility/constants';

type WeeklyTimetableSettingsOverride = Pick<
  ISettingsParams,
  | 'textAlignment'
  | 'calendarLayout'
  | 'headerTitleHtmlTag'
  | 'headerServiceFilterVisibility'
  | 'headerStaffFilterVisibility'
  | 'headerLocationFilterVisibility'
  | 'headerTimezoneSelectionVisibility'
  | 'headerSubtitleVisibility'
  | 'limitTimeSlotsDisplay'
  | 'maxTimeSlotsDisplayedPerDay'
>;

export type IWeeklyTimetableSettingsDefinitions = {
  [key in keyof WeeklyTimetableSettingsOverride]: PartialBy<
    ISettingsParam<WeeklyTimetableSettingsOverride[key]>,
    'key'
  >;
};

export const weeklyTimetableSettingsParamsDefinitions: IWeeklyTimetableSettingsDefinitions =
  {
    calendarLayout: {
      getDefaultValue: ({ isMobile }) =>
        isMobile
          ? LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER
          : LayoutOptions.WEEKLY_TIMETABLE,
    },
    textAlignment: {
      getDefaultValue: () => AlignmentOptions.CENTER,
    },
    headerTitleHtmlTag: {
      getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
    },
    headerServiceFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => true,
    },
    headerStaffFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => true,
    },
    headerLocationFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
    headerTimezoneSelectionVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
    headerSubtitleVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
    maxTimeSlotsDisplayedPerDay: {
      getDefaultValue: () => 1,
    },
    limitTimeSlotsDisplay: {
      getDefaultValue: ({ isMobile }) => isMobile,
    },
  };

const weeklyTimetableSettingsParams =
  createSettingsParams<WeeklyTimetableSettingsOverride>(
    weeklyTimetableSettingsParamsDefinitions,
  );

export default {
  ...calendarSettingsParams,
  ...weeklyTimetableSettingsParams,
};
